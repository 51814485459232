import { Link, useForm } from "@inertiajs/react";
import { FormEvent, memo, useEffect } from "react";
import { z } from "zod";

import BB_Button from "@/Components/BB_Button";
import BB_Meta from "@/Components/BB_Meta";
import LockIcon from "@/Icons/lock.svg?react";
import LogoSmall from "@/Icons/logo_small.svg?react";
import MailIcon from "@/Icons/mail.svg?react";
import RegisterIcon from "@/Icons/register.svg?react";
import SigninIcon from "@/Icons/signin.svg?react";
import GuestLayout from "@/Layouts/GuestLayout";

export type LoginProps = {
	status?: string;
	errors: { [key: string]: string };
	canResetPassword: boolean;
	session: { csrf: string };
};

const FormInputs = z.object({
	email: z
		.string({ message: "Die E-Mail muss vorhanden sein" })
		.email({ message: "Bitte eine gültige E-Mail-Adresse eingeben" })
		.nonempty({ message: "Die E-Mail darf nicht leer sein" }),
	password: z
		.string({ message: "Das Passwort muss vorhanden sein" })
		.min(8, { message: "Das Passwort muss mindestens 8 Zeichen lang sein" })
		.regex(/\d/, { message: "Das Passwort muss mindestens eine Zahl enthalten" })
		.nonempty({ message: "Das Passwort darf nicht leer sein" }),
	remember: z.boolean({ message: "Ungültiger Wert für 'Angemeldet bleiben'" }).optional(),
	_token: z
		.string({ message: "Das Sicherheits-Token muss vorhanden sein" })
		.nonempty({ message: "Das Sicherheits-Token darf nicht leer sein" })
});

type FormSchemaType = z.infer<typeof FormInputs>;
type FieldName = keyof FormSchemaType;

const Login = (props: Readonly<LoginProps>) => {
	const { data, setData, post, reset, setError, errors, clearErrors, processing } =
		useForm<FormSchemaType>({
			email: "",
			password: "",
			remember: true,
			_token: props.session.csrf
		});

	useEffect(() => {
		// show serverside errors
		Object.keys(props.errors).forEach((key) => {
			if (key === "email" || key === "password") setError(key, props.errors[key]);
		});
	}, [props.errors]);

	const validateField = (field: FieldName, value: unknown) => {
		if (value === undefined || value === null || value === "") {
			clearErrors(field);
			return;
		}

		const parsed = FormInputs.safeParse({ [field]: value });

		if (parsed.success) {
			clearErrors(field);
			return;
		}

		const error = parsed.error?.flatten().fieldErrors[field]?.[0];
		if (error) {
			setError(field, error);
		}
	};

	const onSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// Perform client-side validation using Zod
		const result = FormInputs.safeParse(data);

		// show clientside errors
		if (!result.success) {
			const newErrors = result.error.formErrors.fieldErrors;
			Object.keys(newErrors).forEach((key) => {
				if ((key === "email" || key === "password") && newErrors[key]) {
					setError(key, newErrors[key][0]);
				}
			});
			return;
		}

		clearErrors();

		post(route("login"), {
			onError: () => {
				reset("password");
			},
			onFinish: () => {
				reset("password");
			}
		});
	};

	return (
		<GuestLayout>
			<BB_Meta
				title={"Anmelden - Bestell.bar"}
				description="Melde dich an, um fortzufahren."
				keywords="Bestell.bar, Anmelden, Login"
			/>

			<div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<LogoSmall className="mx-auto h-10 w-auto" />
					<h2 className="mt-6 text-center text-2xl leading-9 font-bold tracking-tight text-black dark:text-white">
						Willkommen zurück
					</h2>
					<p className="mt-2 px-6 text-center text-sm text-gray-600 dark:text-gray-400">
						Melde dich an, um fortzufahren.
					</p>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<form
						onSubmit={onSubmit}
						noValidate
						className="bg-background-100 dark:bg-dark-background-300 space-y-6 rounded-none p-6 shadow-lg sm:rounded-lg">
						<div>
							<label
								htmlFor="email"
								className="block text-sm leading-6 font-medium text-gray-900 dark:text-gray-200">
								E-Mail Adresse
							</label>
							<div className="relative mt-2">
								<div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
									<MailIcon className="size-4" />
								</div>
								<input
									autoFocus
									value={data.email}
									onChange={(e) => setData("email", e.target.value)}
									onFocus={() => clearErrors("email")}
									onBlur={(e) => validateField("email", e.target.value)}
									id="email"
									name="email"
									type="email"
									placeholder="max.mustermann@mail.de"
									autoComplete="email"
									className="bg-background-50 dark:bg-dark-background-400 focus:ring-primary-500 block w-full rounded-md border-0 py-1.5 ps-10 text-black shadow-xs ring-1 ring-gray-300 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 dark:text-white"
									required
								/>
							</div>
							{errors.email && (
								<span className="text-sm text-red-500">{errors.email}</span>
							)}
						</div>

						<div>
							<label
								htmlFor="password"
								className="block text-sm leading-6 font-medium text-gray-900 dark:text-gray-200">
								Passwort
							</label>
							<div className="relative mt-2">
								<div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
									<LockIcon className="size-4" />
								</div>
								<input
									value={data.password}
									onChange={(e) => setData("password", e.target.value)}
									onFocus={() => clearErrors("password")}
									onBlur={(e) => validateField("password", e.target.value)}
									id="password"
									name="password"
									type="password"
									placeholder="Passwort"
									autoComplete="current-password"
									className="bg-background-50 dark:bg-dark-background-400 focus:ring-primary-500 block w-full rounded-md border-0 py-1.5 ps-10 text-black shadow-xs ring-1 ring-gray-300 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 dark:text-white"
									required
								/>
							</div>
							{errors.password && (
								<span className="text-sm text-red-500">{errors.password}</span>
							)}
						</div>

						<div className="flex flex-col justify-between sm:flex-row sm:items-center">
							<div className="mb-4 flex items-center sm:mb-0">
								<input
									checked={data.remember}
									onChange={(e) => setData("remember", e.currentTarget.checked)}
									id="remember"
									name="remember"
									type="checkbox"
									autoComplete="on"
									className="bg-background-50 dark:bg-dark-background-400 text-primary-500 ring-primary-500 h-4 w-4 rounded-sm border-gray-300"
								/>
								<label
									htmlFor="remember"
									className="ml-3 block text-sm leading-6 text-gray-900 dark:text-gray-200">
									Angemeldet bleiben
								</label>
							</div>

							{props.canResetPassword && (
								<div className="text-sm leading-6">
									<Link
										href={route("password.request")}
										className="text-primary-500! font-semibold hover:text-black! hover:dark:text-white!">
										Passwort vergessen?
									</Link>
								</div>
							)}
						</div>

						<div>
							<input type="hidden" name="_token" />
							{props.status && (
								<p className="border-primary-500 bg-primary-500/20 my-3 rounded-md border-1 border-dashed p-2 text-sm leading-6 font-medium text-black dark:text-white">
									{props.status}
								</p>
							)}
							<BB_Button
								type="submit"
								buttonClassName={"w-full"}
								disabled={processing}
								icon={SigninIcon}
								iconClassName={"h-5 w-5 mr-2 pointer-events-none"}
								title="Anmelden"
							/>
						</div>
					</form>

					<div className="bg-background-100 dark:bg-dark-background-300 mt-6 rounded-none p-6 shadow-lg sm:rounded-lg">
						<p className="block text-sm leading-6 font-medium text-gray-900 dark:text-gray-200">
							Noch keinen Account?
						</p>
						<BB_Button
							type="secondary"
							onClick={route("register")}
							buttonClassName={"mt-2 w-full"}
							icon={RegisterIcon}
							iconClassName={"h-5 w-5 mr-2 pointer-events-none"}
							title="Jetzt registrieren!"
						/>
					</div>
				</div>
			</div>
		</GuestLayout>
	);
};

export default memo(Login);
